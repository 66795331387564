/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import storage from "../../utils/storage";
import { Button, CircularProgress, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { API_URL } from "../../config";
import Modal from '@mui/material/Modal';
import "./protected.css";
import TableLoader from "../../components/Loader/TableLoader";
import CustomTemplate from "../../components/CustomTemplate";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import moment from 'moment';



function History() {
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false)
  // const handleOpen1 = () => setOpen1(true);
  // const handleClose1 = () => setOpen1(false);
  const [startItem, setStartItem] = useState(0)
  const [endItem, setEndItem] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [logList, setLogList] = useState([])
  const [itemNum, setItemNum] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedRow, setSelectedRow] = useState(null);
  const [htmlContent,setHtmlContent] = useState("")
  // const[open,setOpen]= useState(false)
  const [openSignature,setOpenSignature]=useState(false)
  useEffect(() => {
    getHistory(searchTerm)
    setStartItem((currentPage - 1) * pageSize + 1);

  }, [currentPage, pageSize])
  const debouncedSearch = useCallback(
    debounce((value) => {
      // setCurrentPage(1)
      getHistory(value)
    }, 500),
    []
  );
  const handleSearchChange = (value) => {
    console.log("value", value)
    setCurrentPage(1)
    setSearchTerm(value)
    debouncedSearch(value);
  }
  const handleRowClick = (row) => {
    setSelectedRow(row); // Set selected row data
    setOpen(true); // Open the modal for viewing details
  };
  const handleSignatureModal = (row) => {
    setHtmlContent(row); // Set selected row data
    setOpenSignature(true); // Open the modal for viewing details
  };
  const handleCloseSignature=()=>{
    setOpenSignature(false)
  }
  const handleClose = ()=>{
    setOpen(false)
  }
  const getHistory = async () => {
    setIsLoading(true);
    let apiUrl = `${API_URL}/signature-logs/?page=${currentPage}&page_size=${pageSize}&search=${searchTerm}`;


    try {
      const response = await axios.get(`${apiUrl}`);
      console.log("response", response.data)
      if (currentPage) {
        setItemNum((currentPage - 1) * pageSize + 1);
      }
      setCount(response?.data?.count)
      setLogList(response?.data?.results);
      setTotalPage(response?.data?.total_pages);
      let end = Math.min(currentPage * pageSize, response?.data?.count)
      setEndItem(end)
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        console.log(errorMessage);
      } else {
        console.log("An error occurred");
      }
      setIsLoading(false);
    }
  }
  const handleCountSelect = (event) => {
    setCurrentPage(1)
    setPageSize(event.target.value)
  }
  const handlePageChange = (event,value) => {
    setCurrentPage(value)
  }
  console.log("logList", logList)
  return (
    <>
      <div className="mainWrapper topbar">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <div className="main-header mb-0">
                <h3 className="mt-0">Logs</h3>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="searchBar">
                <TextField
                  id="search"
                  type="search"
                  placeholder="Search"
                  onChange={(e)=>handleSearchChange(e.target.value)}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="mainWrapper userListing commonListing">
        <div className="container-fluid mt-3">
          {logList.length > 0 ?(<>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Initiated At</TableCell>
                    <TableCell >Completed At</TableCell>
                    <TableCell>Processed/Total User</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell>Signature</TableCell>
                  </TableRow>
                </TableHead>
                {isLoading? (
                    <td colSpan={12}>
                      <TableLoader />
                    </td>
                  ) :( <TableBody>
                    {logList.map((row) => (
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{moment(row.created_at).format('MMMM Do YYYY, h:mm:ss A')}</TableCell>
                        <TableCell>{row?.completed_at ? moment(row?.completed_at).format('MMMM Do YYYY, h:mm:ss A'):""}</TableCell>
                        <TableCell>{row.processed_users}/ {row.total_users}</TableCell>
                        <TableCell><span className={row?.status === "COMPLETED" ?'status statusComplete':'status statusFailed' }></span>{row.status}</TableCell>
                        <TableCell>
                          <Button
                            variant="text"
                            className="commonAction editButton"
                            title="View Result"
                            onClick={() => handleRowClick(row.results)} // Handle row click
                          >
                            <i className="fa-solid fa-eye"></i>
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button variant="text" className="commonAction editButton" title="View" onClick={()=>handleSignatureModal(row.signature_content)}><i class="fa-solid fa-eye"></i></Button>
                        </TableCell>
                      </TableRow>
                    ))}
  
                  </TableBody>)}
               
                {/* 
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>Vernon Holmes</TableCell>
                    <TableCell>06 Dec, 2024 10:00Am</TableCell>
                    <TableCell>User1, User2, User3</TableCell>
                    <TableCell><span className='status statusIncomplete'></span>Inprogress</TableCell>
                    <TableCell><Button variant="text" className="commonAction editButton" title="View" onClick={handleOpen1}><i class="fa-solid fa-eye"></i></Button></TableCell>
                    <TableCell>
                      <Button variant="text" className="commonAction editButton" title="View" onClick={handleOpen}><i class="fa-solid fa-eye"></i></Button>
                    </TableCell>
                  </TableRow>
                </TableBody> */}
              </Table>
            </TableContainer>
          </>)
           :(
                            <>
                                {isLoading ? <TableLoader /> :
                                    <div className="make-center emptyList">
                                        <i className="fa-solid fa-box-archive"></i>
                                        <p>No Logs found</p>
                                    </div>
                                }
                            </>
                        )}


          {
            !isLoading && logList.length>0 ?  
          (  <div className="row my-4">
              <div className="col-lg-6">
                <div className="pagination">
                  <select onChange={handleCountSelect} value={pageSize}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                  </select>
                  {Math.min(startItem, count)} to {endItem} of {count} Entries
                </div>
              </div>
              <div className="col-lg-6">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </Stack>
              </div>
            </div>):""
          }


        </div>
      </div>


      <div>

        <Modal
          // users = {users} 
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className='signatureView'>
          <div className='modal-header'>
            <h3>View Result</h3>
            <button className='bnt btn-close' onClick={handleClose}><i class="fa-regular fa-circle-xmark"></i></button>
          </div>

            <div className='userListing commonListing'>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>User Email</TableCell>
                      <TableCell >Status</TableCell>
                      <TableCell >Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedRow?.length >0 ?
                      selectedRow.map((row) => (

                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>{row.user_email}</TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>{row.details}</TableCell>
                        </TableRow>
                      )):( <tr>
                        <td colSpan={3}>
                          <div className="text-center emptyList">
                            <i className="fa-solid fa-box-archive"></i>
                            <p>No Record found</p>
                        </div>
                        </td>
                      </tr>)
                    }
                  </TableBody>

                  {/* <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>Vernon Holmes</TableCell>
                      <TableCell>06 Dec, 2024 10:00Am</TableCell>
                    </TableRow>
                  </TableBody> */}
                </Table>
              </TableContainer>
            </div>

          </div>
        </Modal>
        <Modal
          open={openSignature}
          onClose={handleCloseSignature}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className='signatureView'>
            <div className='modal-header'>
              <h3>View Signature</h3>
              <button className='bnt btn-close' onClick={handleCloseSignature}><i class="fa-regular fa-circle-xmark"></i></button>
            </div>
            <div className="previewSectionInner">
              <div
                dangerouslySetInnerHTML={{
                  __html: htmlContent,
                }}
              />
            </div>

          </div>
        </Modal>
      </div>
    </>
  );
}

export default History;

