import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Switch,
  styled,
  TextField,
  Pagination,
  Box,
  Typography
} from '@mui/material';
import { API_URL } from '../config';
import { toast } from 'react-toastify';

// Add styled components for better design
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '12px',
    boxShadow: '0 8px 24px rgba(0,0,0,0.12)'
  }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid #e9ecef',
  padding: '20px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));
console.log("open----")
function SendAsModal({ open, onClose, user, setUpdateList }) {
    console.log("open----", open, onClose, user)
  const [sendAsData, setSendAsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updatedData, setUpdatedData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [pagination, setPagination] = useState({
    count: 0,
    totalPages: 1,
    currentPage: 1
  });
  const [openAliasDialog, setOpenAliasDialog] = useState(false);
  const [newAlias, setNewAlias] = useState('');
  const [addingAlias, setAddingAlias] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [removingEmail, setRemovingEmail] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    email: null
  });
  console.log("modal open", open)
  useEffect(() => {
    

    if (open) {
      fetchSendAsData();
    }
  }, [open, user, pagination.currentPage, searchQuery]);

  useEffect(() => {
    if (sendAsData.length > 0) {
      setUpdatedData(sendAsData);
    }
  }, [sendAsData]);

  const fetchSendAsData = async () => {
    try {
      if (user?.id) {
        const queryParams = new URLSearchParams({
          page: pagination.currentPage,
          search: searchQuery
        }).toString();
        setLoading(true)

        const response = await fetch(`${API_URL}/add-send-as/${user.id}/?${queryParams}`);
        const result = await response.json();
        
        if (response.ok) {
          setSendAsData(result.results);
          setPagination({
            count: result.count,
            totalPages: result.total_pages,
            currentPage: result.page_number
          });
          setLoading(false)
        } else {
          setError(result.error || 'Failed to fetch SendAs data');
          setLoading(false)
        }
      }
    } catch (err) {
      setError('Failed to fetch SendAs data');
      setLoading(false)
    } finally {
      setLoading(false);
    }
  };

  const handleToggleChange = (email, field) => {
    setUpdatedData(prev => {
      if (field === 'is_primary' && prev.some(row => row.email === email && !row.is_primary)) {
        // If setting a new default email, unset all others
        return prev.map(row => ({
          ...row,
          is_primary: row.email === email
        }));
      } else if (field === 'is_primary' && prev.some(row => row.email === email && row.is_primary)) {
        // If trying to unset the only default email, prevent the action
        return prev;
      } else {
        // For send_as or other fields, toggle normally
        return prev.map(row => {
          if (row.email === email) {
            return { ...row, [field]: !row[field] };
          }
          return row;
        });
      }
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      console.log("updatedData", updatedData)
      // only send as
      const sendAsData = updatedData.filter(row => row.send_as);
      console.log("sendAsData", sendAsData)
      const response = await fetch(`${API_URL}/add-send-as/${user.id}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: sendAsData,
          page: pagination.currentPage,
          search: searchQuery
        }),
      });
      const result = await response.json();
      if (result.status) {
        setSendAsData(sendAsData);
        onClose();
        setUpdateList(true)
        toast.success('SendAs data updated successfully');
      } else {
        setError(result.error || 'Failed to update SendAs data');
      }
    } catch (err) {
      setError('Failed to update SendAs data');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPagination(prev => ({
      ...prev,
      currentPage: value
    }));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPagination(prev => ({
      ...prev,
      currentPage: 1 // Reset to first page when searching
    }));
  };

  const handleAddAlias = async () => {
    try {
      setAddingAlias(true);
      const response = await fetch(`${API_URL}/add-admin-alias/${user.id}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newAlias }),
      });
      const result = await response.json();
      
      if (response.ok) {
        toast.success('Alias added successfully');
        setOpenAliasDialog(false);
        setNewAlias('');
        // Refresh the SendAs data
        fetchSendAsData();
      } else {
        toast.error(result.error || 'Failed to add alias');
      }
    } catch (err) {
      toast.error('Failed to add alias');
    } finally {
      setAddingAlias(false);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setNewAlias(email);
    setIsValidEmail(validateEmail(email));
  };

  const handleRemoveEmail = async (email) => {
    try {
      setRemovingEmail(email);
      const response = await fetch(`${API_URL}/add-admin-alias/${user.id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      
      if (response.ok) {
        setUpdatedData(prev => prev.filter(row => row.email !== email));
        toast.success('Email alias removed successfully');
      } else {
        const result = await response.json();
        toast.error(result.error || 'Failed to remove email alias');
      }
    } catch (err) {
      toast.error('Failed to remove email alias');
    } finally {
      setRemovingEmail(null);
      setConfirmDialog({ open: false, email: null });
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <StyledDialogTitle>
        <div>Alias List for {user?.name?.fullName}</div>
        <Button
          variant="contained"
          color="success"
          onClick={() => setOpenAliasDialog(true)}
          size="small"
          startIcon={<span style={{ fontSize: '20px' }}>+</span>}
          sx={{
            borderRadius: '8px',
            textTransform: 'none',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            '&:hover': {
              boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
            }
          }}
        >
          Add Alias
        </Button>
      </StyledDialogTitle>

      {/* Add New Alias Dialog */}
      <Dialog 
        open={openAliasDialog} 
        onClose={() => setOpenAliasDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            minWidth: '400px',
            boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          backgroundColor: '#f8f9fa',
          padding: '20px 24px',
          fontSize: '1.1rem',
          fontWeight: 500
        }}>
          Add New Email Alias
        </DialogTitle>
        <DialogContent sx={{ 
          padding: '24px',
          paddingTop: '24px !important'
        }}>
          <TextField
            autoFocus
            label="Email Alias"
            type="email"
            fullWidth
            value={newAlias}
            onChange={handleEmailChange}
            error={newAlias !== '' && !isValidEmail}
            helperText={newAlias !== '' && !isValidEmail ? 'Please enter a valid email address' : ''}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ 
          padding: '16px 24px',
          borderTop: '1px solid #e0e0e0'
        }}>
          <Button 
            onClick={() => {
              setOpenAliasDialog(false);
              setNewAlias('');
              setIsValidEmail(false);
            }}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)'
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleAddAlias}
            variant="contained"
            color="success"
            disabled={!isValidEmail || addingAlias}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              minWidth: '100px',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }
            }}
          >
            {addingAlias ? 'Adding...' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog 
        open={confirmDialog.open} 
        onClose={() => setConfirmDialog({ open: false, email: null })}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            minWidth: '400px',
            boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          backgroundColor: '#f8f9fa',
          padding: '20px 24px',
          fontSize: '1.1rem',
          fontWeight: 500
        }}>
          Confirm Deletion
        </DialogTitle>
        <DialogContent sx={{ padding: '24px' }}>
          Are you sure you want to remove this email alias?
          <Typography color="error" sx={{ mt: 1, fontWeight: 500 }}>
            {confirmDialog.email}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px', borderTop: '1px solid #e0e0e0' }}>
          <Button 
            onClick={() => setConfirmDialog({ open: false, email: null })}
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={() => handleRemoveEmail(confirmDialog.email)}
            variant="contained"
            color="error"
            disabled={removingEmail === confirmDialog.email}
          >
            {removingEmail === confirmDialog.email ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Remove'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogContent sx={{ padding: '24px' }}>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search emails..."
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              }
            }}
          />
        </Box>
        
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
        ) : error ? (
          <div style={{ color: 'red', padding: '20px' }}>{error}</div>
        ) : (
          <>
            <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #e0e0e0', mb: 2 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                    <TableCell>Email</TableCell>
                    <TableCell align="center">
                      Send As
                      <Typography variant="caption" display="block" color="textSecondary">
                        (Multiple allowed)
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      Default
                      <Typography variant="caption" display="block" color="textSecondary">
                        (Only one allowed)
                      </Typography>
                    </TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {updatedData.map((row) => (
                    <TableRow 
                      key={row.email} 
                      sx={{ 
                        '&:hover': { backgroundColor: '#f8f9fa' },
                        backgroundColor: row.is_primary ? 'rgba(76, 175, 80, 0.08)' : 'inherit'
                      }}
                    >
                      <TableCell>{row.email}</TableCell>
                      <TableCell align="center">
                        <Switch
                          checked={row.send_as}
                          onChange={() => handleToggleChange(row.email, 'send_as')}
                          color="success"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Switch
                          checked={row.is_primary}
                          onChange={() => handleToggleChange(row.email, 'is_primary')}
                          color="success"
                          disabled={!row.send_as}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {row.is_alias ? (
                          <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={() => setConfirmDialog({ open: true, email: row.email })}
                            disabled={removingEmail === row.email}
                          >
                            {removingEmail === row.email ? (
                              <CircularProgress size={20} color="error" />
                            ) : (
                              'Remove'
                            )}
                          </Button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                  {updatedData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={4} align="center" sx={{ padding: '20px' }}>
                        No SendAs emails found for this user.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={pagination.totalPages}
                page={pagination.currentPage}
                onChange={handlePageChange}
                color="success"
                shape="rounded"
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px', borderTop: '1px solid #e9ecef' }}>
        <Button onClick={onClose} color="inherit" sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          color="success"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save Changes'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default SendAsModal;
