import { Navigate, Outlet } from "react-router-dom";
import { Suspense } from "react";
import Header from "../../layout/header";
import NotFound from "../public/notFound";
import UserList from "./userList";
import History from "./history";
import Sidebar from "../../layout/sidebar";
import TemplateList from "./TemplateList";
import CreateTemplate, { UpdateTemplate } from "../../components/CreateTemplate";
import CreateTest from "../../components/CreateTest";
import DomainList from "./DomainList";
import DomainUsers from "../../components/DomainUsers";
import PermissionDenied from "../public/permissionDenied";

const App = () => {

  
  return (
    <div>
      <Suspense
        fallback={
          <div className="w-screen h-screen alignmentLogo">Any Image Here</div>
        }
      >
        {/* <Header /> */}
        <Outlet />
      </Suspense>
    </div>
  );
};

export const protectedRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/user-list", element: <UserList /> },
      { path: "/template", element: <TemplateList /> },
      { path: "/create-template", element: <CreateTemplate /> },
      { path: "/create-test", element: <CreateTest /> },
      { path: "/update-template", element: <UpdateTemplate /> },
      { path: "/domains", element: <DomainList /> },
      { path: "/users", element: <DomainUsers /> },
      { path: "/permission-denied", element: <PermissionDenied /> },
      { path: "/history", element: <History /> },



      { path: "*", element: <Navigate to="/not-found" /> },
    ],
  },
];
