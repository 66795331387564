
import { useEffect, useState } from "react";
import CustomTemplate from "./CustomTemplate";
import { API_URL } from "../config";
import TableLoader from "./Loader/TableLoader";

const OpenModal = ({ userDetails, templateData, open, handleClose }) => {

  const [editorData, setEditorData] = useState("");
  const [userData,setUserData] = useState([])


  const handleEditorChange = (event, editor) => {
    setEditorData(editor.getData());
  };

  // console.log("templateData", templateData);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(userDetails?.primaryEmail);
  const [content, setContent] = useState("");
  const [templateId, setTemplateId] = useState(0);
  const [email, setEmail] = useState([userDetails?.primaryEmail]);
  const [showUser,setShowUser] = useState(false)
  const [htmlContent, setHtmlContent] = useState("");


  useEffect(() => {
    getUserData()
  }, []);

  // Close modal when Esc key is pressed
  useEffect(() => {
    if (open) {
      const handleEscKey = (event) => {
        if (event.key === "Escape") {
          handleClose();
        }
      };

      // Add the event listener for Esc key
      document.addEventListener("keydown", handleEscKey);

      // Cleanup the event listener when the component unmounts or modal is closed
      return () => {
        document.removeEventListener("keydown", handleEscKey);
      };
    }
  }, [open, handleClose]);
  const getUserData = async()=>{
   await fetch(`${API_URL}/get-aliases/`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body:
       JSON.stringify({
      emails:email
      }),
  })
      .then((response) => response.json())
      .then((data) => {
          setUserData(data?.data)
          setShowUser(true)
          // toast.success("Content submitted successfully!");
          // navigate("/template")

      })
      .catch((error) => {
          console.error("Error submitting content:", error);
          // toast.error("An error occurred while submitting the content.");
      });
  }
 
  // Handle checkbox click event
  const handleCheckboxClick = async (templateId) => {
    if (templateId === selectedTemplate) {
      setTemplateId(0); // Reset templateId to 0
      setSelectedTemplate(0); // Reset selectedTemplate to 0
    } else {
      setTemplateId(templateId); // Set templateId to the clicked template's ID
      setSelectedTemplate(templateId); // Set selectedTemplate to the clicked template's ID
    }
  };
  console.log("template Id",templateId)
  const handleEmailCheckboxClick = async (email) => {
    // Toggle selection for single select checkbox
    setEmail(email);
    setSelectedEmail(email === selectedEmail ? email : email);
    userDetails['alias_emails']=[email]
  };

  return (
    <>
      {open && (
        <div className="newComponentOuter" style={{ display: "inline-flex" }}>
          <div className="newComponentInner">
            <p className="header">Update Signature</p>
            <button className="closeBtn" title="Close" onClick={handleClose}>
                <i class="fa-regular fa-circle-xmark"></i>
            </button>
            {showUser && userData && userData.length > 0 ? (
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="userOuter">
                        <h4>User List</h4>
                        <div className="users">
                        {userData.map((item)=>(<>
                        
                              {item?.aliases?.map((row)=>(
                            <div className="checkBoxSection form-check">
                                    <>
                                    <span>
                                    <input
                                        type="checkbox" className="form-check-input"
                                        checked={selectedEmail === row}
                                        onChange={() => handleEmailCheckboxClick(row)}
                                    />
        
                                    </span>
                                    <span className="aliaseName">{row}</span>
                                    </>

                            </div>   
                              ))}
                              </>
                          ))}

                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 templateListContainer">
                      {templateData.length > 0 && (
                        <div className="template-section">
                          <h4>Template List</h4>
                          {templateData.map((item, index) => (
                            <div className="checkBoxSection form-check" key={index}>
                              <span>
                                <input
                                  type="checkbox" className="form-check-input"
                                  checked={selectedTemplate === item.id}
                                  onChange={() => handleCheckboxClick(item.id)}
                                />
                              </span>
                              <span className="aliaseName">{item.template_name}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12">
                      <div className="preview mt-3">
                        <h4>Preview</h4>

                         {/* Render the HTML content using dangerouslySetInnerHTML */}
                        <div className="previewSectionInner">
                        <div
        dangerouslySetInnerHTML={{
          __html: htmlContent,
        }}
      />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 templateListContainer">
                  <CustomTemplate templateId={templateId} email={email}  editable={false} userDetails={userDetails} handleClose={handleClose} setHtmlContent={setHtmlContent}/> 
                </div>
              </div>
            ) : (
              // "No user data available"
              !showUser ? <TableLoader/>:"No Record Found"
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OpenModal;

// const OpenModal= ({ userData, templateData, open, handleClose })=>{
//   console.log("calling open modal")
//      const [editorData, setEditorData] = useState("");
//     useEffect(() => {
//       if (userData) {
//         setEditorData(userData.primaryEmail);
//       }
//     }, [userData]);
  
//     const handleEditorChange = (event, editor) => {
//       setEditorData(editor.getData());
//     };
//     console.log("templateData", templateData)
//     const [selectedTemplate, setSelectedTemplate] = useState(null);
//     const [selectedEmail, setSelectedEmail] = useState(null);
//     const [content, setContent] = useState("")
//     const [templateId, setTemplateId] = useState(0)
//     const [email, setEmail] = useState("")
//     // Handle checkbox click event
//     const handleCheckboxClick = async (templateId) => {
//       // Toggle selection for single select checkbox
//       setTemplateId(templateId)
//       setSelectedTemplate(templateId === selectedTemplate ? null : templateId);
//     };
//     const handleEmailCheckboxClick = async (email) => {
//       // Toggle selection for single select checkbox
//       setEmail(email)
//       setSelectedEmail(email === selectedEmail ? null : email);
//     };
//     console.log("Email template", email, templateId)
//     return (
//       <>
//       {open &&  <div className="newComponentOuter" style={{display:'inline-flex'}}>
//         <div className="newComponentInner">
//           <p className="header"> Update Signature </p>
//           <button onClick={handleClose}><i class="fa-solid fa-xmark"></i></button>
//             {userData && Object.keys(userData).length > 0 ? (
//               <div className="row">
//                 <div className="col-md-6">
//                   <div className="row">
//                     <div className="col-md-9">
//                       <div className="userOuter">
//                         <h4>User List</h4>
//                         <div className="users">
//                           <div className="checkBoxSection">
//                             {/* Primary email checkbox */}
//                             <span>
//                               <input
//                                 type="checkbox"
//                                 checked={selectedEmail === userData?.primaryEmail}
//                                 onChange={() => handleEmailCheckboxClick(userData?.primaryEmail)}
//                               />
//                             </span>
//                             <span className="aliaseName">{userData.primaryEmail}</span>
//                           </div>
    
//                           {/* Aliases checkboxes */}
//                           {userData.aliases.map((alias, index) => (
//                             <div className="checkBoxSection" key={index}>
//                               <span>
//                                 <input
//                                   type="checkbox"
//                                   checked={selectedEmail === alias}
//                                   onChange={() => handleEmailCheckboxClick(alias)}
//                                 />
//                               </span>
//                               <span className="aliaseName">{alias}</span>
//                             </div>
//                           ))}
//                         </div>
//                       </div>
//                     </div>
  
//                     {templateData.length > 0 ? (
//                       <div className="col-md-6 border p-3">
  
//                        <div className="template-section">
//                         <h4>Template List</h4>
//                           {templateData.map((item, index) => (
//                             <div className="checkBoxSection" key={index}>
//                               <span>
//                                 <input
//                                   type="checkbox"
//                                   checked={selectedTemplate === item.id}
//                                   onChange={() => handleCheckboxClick(item.id)}
//                                 />
//                               </span>
//                               <span className="aliaseName">{item.template_name}</span>
//                             </div>
//                           ))}
//                        </div>
//                       </div>
//                     ) : null}
  
//                   </div>
//                 </div>
                
//                 <div className="col-md-6">
//                 <CustomTemplate templateId={templateId} email={email}  editable={false} /> 
//                   {/* <MyEditorComponent/> */}
//                 </div>
//               </div>
//             ) : (
//               "No user data available"
//             )}
//           </div>
//       </div> }
     
//       </>
//     );
// }

// export default OpenModal;
























// const OpenModal = ({ userData, templateData, open, handleClose }) => {
//   const [editorData, setEditorData] = useState("");
//   useEffect(() => {
//     if (userData) {
//       setEditorData(userData.primaryEmail);
//     }
//   }, [userData]);

//   const handleEditorChange = (event, editor) => {
//     setEditorData(editor.getData());
//   };
//   console.log("templateData", templateData)
//   const [selectedTemplate, setSelectedTemplate] = useState(null);
//   const [selectedEmail, setSelectedEmail] = useState(null);
//   const [content, setContent] = useState("")
//   const [templateId, setTemplateId] = useState(0)
//   const [email, setEmail] = useState("")
//   // Handle checkbox click event
//   const handleCheckboxClick = async (templateId) => {
//     // Toggle selection for single select checkbox
//     setTemplateId(templateId)
//     setSelectedTemplate(templateId === selectedTemplate ? null : templateId);
//   };
//   const handleEmailCheckboxClick = async (email) => {
//     // Toggle selection for single select checkbox
//     setEmail(email)
//     setSelectedEmail(email === selectedEmail ? null : email);
//   };
//   console.log("Email template", email, templateId)
//   return (
//     <BootstrapDialog
//       className="dialogOuter"
//       onClose={handleClose}
//       aria-labelledby="customized-dialog-title"
//       open={open}
//     >
//       <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
//         Update Signature
//       </DialogTitle>
    
//       <IconButton
//         aria-label="close"
//         onClick={handleClose}
//         sx={{
//           position: "absolute",
//           right: 8,
//           top: 8,
//           color: (theme) => theme.palette.grey[500],
//         }}
//       >
//         <CloseIcon />
//       </IconButton>
//       <DialogContent dividers>
//         <Typography gutterBottom component="div">
//           {userData && Object.keys(userData).length > 0 ? (
//             <div className="row">
//               <div className="col-md-6">
//                 <div className="row">
//                   <div className="col-md-6 border p-3">
//                     <h4>User List</h4>
//                     <div className="users">
//                       <div className="checkBoxSection">
//                         {/* Primary email checkbox */}
//                         <span>
//                           <input
//                             type="checkbox"
//                             checked={selectedEmail === userData?.primaryEmail}
//                             onChange={() => handleEmailCheckboxClick(userData?.primaryEmail)}
//                           />
//                         </span>
//                         <span className="aliaseName">{userData.primaryEmail}</span>
//                       </div>

//                       {/* Aliases checkboxes */}
//                       {userData.aliases.map((alias, index) => (
//                         <div className="checkBoxSection" key={index}>
//                           <span>
//                             <input
//                               type="checkbox"
//                               checked={selectedEmail === alias}
//                               onChange={() => handleEmailCheckboxClick(alias)}
//                             />
//                           </span>
//                           <span className="aliaseName">{alias}</span>
//                         </div>
//                       ))}
//                     </div>
//                   </div>

//                   {templateData.length > 0 ? (
//                     <div className="col-md-6 border p-3">

//                       <h4>Template List</h4>
//                       {templateData.map((item, index) => (
//                         <div className="checkBoxSection" key={index}>
//                           <span>
//                             <input
//                               type="checkbox"
//                               checked={selectedTemplate === item.id}
//                               onChange={() => handleCheckboxClick(item.id)}
//                             />
//                           </span>
//                           <span className="aliaseName">{item.template_name}</span>
//                         </div>
//                       ))}
//                     </div>
//                   ) : null}

//                 </div>
//               </div>
              
//               <div className="col-md-6">
//               {/* <CustomTemplate templateId={templateId} email={email}  editable={false} />  */}
//                 <MyEditorComponent/>
//               </div>
//             </div>
//           ) : (
//             "No user data available"
//           )}
//         </Typography>
//       </DialogContent>
//     </BootstrapDialog>
//   );
// };