import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./routes";
import { ToastContainer } from "react-toastify";
import "../src/App.css";
import Sidebar from "./layout/sidebar";
import { useState, useEffect } from "react";
import ForbiddenPage from "./pages/public/permissionDenied";
import { ALLOWED_APP } from "./config";

function App() {
  const [isForbidden, setIsForbidden] = useState(false);
  const allowedReferrers = ALLOWED_APP
  ? ALLOWED_APP.split(",")  // Split by commas
  : [];

  useEffect(() => {
    // 1. Check if the app is inside an iframe
    if (window.top === window.self) {
      // If the app is not inside an iframe, set forbidden state to true
      setIsForbidden(true);
    }

    // 2. Check if the app is being accessed from a valid referrer (your PHP site)
    const referrer = document.referrer;

    if (!referrer || !allowedReferrers.some((allowedReferrer) => referrer.startsWith(allowedReferrer))) {
      // If the referrer is not from any of the allowed apps
      setIsForbidden(true);
    }
  }, []);  
  // If forbidden, render the ForbiddenPage component
  if (isForbidden) {
    return <ForbiddenPage />;
  }

  return (
    <Router>
      {/* <Sidebar /> */}
      <ToastContainer />
      <AppRoutes />
    </Router>
  );
}

export default App;
