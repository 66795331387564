// src/Sidebar.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './sidebar.css'; // Import the CSS file for styles

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false); // State to track sidebar visibility
  const location = useLocation(); // Get the current URL path

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Toggle button for mobile view */}
      <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
        {isOpen ? 'Close' : 'Open'} Sidebar
      </button>

      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <nav className="sidebar-nav">
          <ul>
            {/* Add active class based on the current URL */}
            <li
              className={
                location.pathname === '/' || location.pathname === '/user-list'
                  ? 'active'
                  : ''
              }
            >
              <a href="/user-list"><i class="fa-solid fa-user"></i> User List</a>
            </li>
            <li className={location.pathname === '/template' ? 'active' : ''}>
              <a href="/template"><i class="fa-solid fa-file-lines"></i> Template</a>
            </li>
            <li className={location.pathname === '/domains' ? 'active' : ''}>
              <a href="/domains"><i class="fa-solid fa-globe"></i> Domains</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
