import { useEffect, useState } from "react";
import CustomTemplate from "./CustomTemplate";
import { API_URL } from "../config";
import TableLoader from "./Loader/TableLoader";
import SendAsModal from "./SendAsModal";
const OpenModal = ({ userDetails, templateData, open, handleClose }) => {

  const [editorData, setEditorData] = useState("");
  const [userData,setUserData] = useState([])


  const handleEditorChange = (event, editor) => {
    setEditorData(editor.getData());
  };

  // console.log("templateData", templateData);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(userDetails?.primaryEmail);
  const [content, setContent] = useState("");
  const [templateId, setTemplateId] = useState(0);
  const [email, setEmail] = useState([userDetails?.primaryEmail]);
  const [showUser,setShowUser] = useState(false)
  const [htmlContent, setHtmlContent] = useState("");
  const [sendAsOpen, setSendAsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const[updateList,setUpdateList] = useState(false)

  useEffect(() => {
    getUserData()
  }, [updateList]);

  // Close modal when Esc key is pressed
  useEffect(() => {
    if (open) {
      const handleEscKey = (event) => {
        if (event.key === "Escape") {
          handleClose();
        }
      };

      // Add the event listener for Esc key
      document.addEventListener("keydown", handleEscKey);

      // Cleanup the event listener when the component unmounts or modal is closed
      return () => {
        document.removeEventListener("keydown", handleEscKey);
      };
    }
  }, [open, handleClose]);
  const getUserData = async()=>{
   await fetch(`${API_URL}/get-aliases/`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body:
       JSON.stringify({
      emails:email
      }),
  })
      .then((response) => response.json())
      .then((data) => {
          setUserData(data?.data)
          setShowUser(true)
          setUpdateList(false)
          // toast.success("Content submitted successfully!");
          // navigate("/template")

      })
      .catch((error) => {
          console.error("Error submitting content:", error);
          // toast.error("An error occurred while submitting the content.");
      });
  }
  const handleViewSendAs = async (user) => {
    setSelectedUser(user);
    setSendAsOpen(true);
  };
 
  // Handle checkbox click event
  const handleCheckboxClick = async (templateId) => {
    if (templateId === selectedTemplate) {
      setTemplateId(0); // Reset templateId to 0
      setSelectedTemplate(0); // Reset selectedTemplate to 0
    } else {
      setTemplateId(templateId); // Set templateId to the clicked template's ID
      setSelectedTemplate(templateId); // Set selectedTemplate to the clicked template's ID
    }
  };
  console.log("template Id",templateId)
  const handleEmailCheckboxClick = async (email) => {
    // Toggle selection for single select checkbox
    setEmail(email);
    setSelectedEmail(email === selectedEmail ? email : email);
    userDetails['alias_emails']=[email]
  };

  return (
    <>
      {open && (
        <div className="newComponentOuter" style={{ display: "inline-flex" }}>
          <div className="newComponentInner">
            <p className="header">
              Update Signature
              <button 
                className="btn ms-2" 
                style={{ backgroundColor: '#28a745', color: 'white' }}
                onClick={() => handleViewSendAs(userDetails)}
                title="Alias List"
              >
                Alias List
              </button>
            </p>
            <button className="closeBtn" title="Close" onClick={handleClose}>
                <i class="fa-regular fa-circle-xmark"></i>
            </button>
            {showUser && userData && userData.length > 0 ? (
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="userOuter">
                        <h4>User List</h4>
                        <div className="users">
                        {userData.map((item)=>(<>
                        
                              {item?.aliases?.map((row)=>(
                            <div className="checkBoxSection form-check">
                                    <>
                                    <span>
                                    <input
                                        type="checkbox" className="form-check-input"
                                        checked={selectedEmail === row}
                                        onChange={() => handleEmailCheckboxClick(row)}
                                    />
        
                                    </span>
                                    <span className="aliaseName">{row}</span>
                                    </>

                            </div>   
                              ))}
                              </>
                          ))}

                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 templateListContainer">
                      {templateData.length > 0 && (
                        <div className="template-section">
                          <h4>Template List</h4>
                          {templateData.map((item, index) => (
                            <div className="checkBoxSection form-check" key={index}>
                              <span>
                                <input
                                  type="checkbox" className="form-check-input"
                                  checked={selectedTemplate === item.id}
                                  onChange={() => handleCheckboxClick(item.id)}
                                />
                              </span>
                              <span className="aliaseName">{item.template_name}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12">
                      <div className="preview mt-3">
                        <h4>Preview</h4>

                         {/* Render the HTML content using dangerouslySetInnerHTML */}
                        <div className="previewSectionInner">
                        <div
        dangerouslySetInnerHTML={{
          __html: htmlContent,
        }}
      />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 templateListContainer">
                  <CustomTemplate templateId={templateId} email={email}  editable={false} userDetails={userDetails} handleClose={handleClose} setHtmlContent={setHtmlContent}/> 
                </div>
              </div>
            ) : (
              // "No user data available"
              !showUser ? <TableLoader/>:"No Record Found"
            )}
          </div>
        </div>
      )}
      {sendAsOpen && selectedUser && (
        <SendAsModal
          
          open={sendAsOpen}
          onClose={() => {
            setSendAsOpen(false);
            setSelectedUser(null);
          }}
          user={selectedUser}
          setUpdateList ={setUpdateList}
        />
      )}
    </>
  );
};

export default OpenModal;

