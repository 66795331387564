/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import storage from "../../utils/storage";
import {
    Button,
    CircularProgress,
    InputAdornment,
    TextField,
} from "@mui/material";
import BackdropLoader from "../../components/Loader/BackdropLoader";
import "./protected.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { API_URL } from "../../config";
import CustomTemplate from "../../components/CustomTemplate";
import TableLoader from "../../components/Loader/TableLoader";
import { toast } from "react-toastify";
import { debounce } from "lodash";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function DomainList() {
    const token = storage.getToken();
    const [isLoading, setLoading] = useState(true);
    const [clickDelete, setClickDelete] = useState(false);
    const [templateList, settemplateList] = useState([]);
    const [userId, setId] = useState("");
    const [totalPage, setTotalPage] = useState(1);
    const [userData, setuserData] = useState({
        primaryEmail: "",
        aliases: [],
    });
    const [selectedRole, setRole] = useState("user");
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemNum, setItemNum] = useState(1);
    const [content, setContent] = useState("")
    const [startItem, setStartItem] = useState(0)
    const [endItem, setEndItem] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [count, setCount] = useState(0)
    const [domainList, setDomainList] = useState([])
    useEffect(() => {
        console.log("Qwerty", totalPage, currentPage);
        getDomainListing(searchTerm);
        setStartItem((currentPage - 1) * pageSize + 1);
    }, [currentPage, pageSize]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const handleCountSelect = (event) => {
        setCurrentPage(1)
        setPageSize(event.target.value)
    }
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        console.log("clicked");
    };
    const debouncedSearch = useCallback(
        debounce((value) => {
            console.log("searchTerm", value)
            // setCurrentPage(1)
            getDomainListing(value)
        }, 500),
        []
    );
    const handleSearchChange = (value) => {
        console.log("value", value)
        setCurrentPage(1)
        setSearchTerm(value)
        debouncedSearch(value);
    }



    const handleChange = (event) => {
        setRole(event.target.value);
    };
    const handleViewUsers = (domain) => {
        navigate(`/users?domain=${domain}`)
    }
    const getDomainListing = async (value) => {
        setLoading(true);
        let apiUrl = `${API_URL}/get-domains/?verified=true&page=${currentPage}&page_size=${pageSize}&search=${value}`;


        try {
            const response = await axios.get(`${apiUrl}`);
            if (currentPage) {
                setItemNum((currentPage - 1) * pageSize + 1);
            }
            setCount(response?.data?.count)
            setDomainList(response?.data?.results);
            setTotalPage(response?.data?.total_pages);
            let end = Math.min(currentPage * pageSize, response?.data?.count)
            setEndItem(end)
            setLoading(false);
        } catch (error) {
            if (error.response) {
                const errorMessage = error.response.data.message;
                console.log(errorMessage);
            } else {
                console.log("An error occurred");
            }
            setLoading(false);
        }
    };



    console.log("useData", userData, open);
    return (
        <>
            <div className="mainWrapper topbar">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="main-header mb-0">
                                <h3 className="mt-3">Domain Information</h3>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <TextField
                                id="search"
                                type="search"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(e) => handleSearchChange(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="col-md-2 selectDiv">
                            {/* <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Select Role
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedRole}
                            label="Select Role"
                            onChange={handleChange}
                        >
                            <MenuItem value={"user"}>User</MenuItem>
                            <MenuItem value={"admin"}>Admin</MenuItem>
                        </Select>
                        </FormControl> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mainWrapper commonListing">
                <div className="container-fluid mt-3">


                    {
                        domainList?.length > 0 ? (
                            <>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 800 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>S.no.</StyledTableCell>
                                                <StyledTableCell>Domain Name</StyledTableCell>
                                                <StyledTableCell>Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>

                                        {isLoading ? (
                                            <td colSpan={3}>
                                                <TableLoader />
                                            </td>
                                        ) : (
                                            <TableBody>
                                                {domainList?.map((item, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell component="th" scope="item">
                                                            {index + itemNum}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="item">
                                                            {item.domainName}
                                                        </StyledTableCell>
                                                        <StyledTableCell>

                                                            <Button
                                                                className="commonAction editButton"
                                                                title="Edit"
                                                                onClick={(e) => { handleViewUsers(item?.domainName) }}
                                                            >
                                                                <i class="fa-solid fa-eye"></i>
                                                            </Button>


                                                            {/* <div class="dropdown">
                                                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="fa-solid fa-ellipsis-vertical"></i>
                                                                </button>
                                                                <ul class="dropdown-menu">
                                                                
                                                                </ul>
                                                            </div> */}

                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                                {/* <div className="row m-4 rht">
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={totalPage}
                                            variant="outlined"
                                            shape="rounded"
                                            page={currentPage}
                                            onChange={handlePageChange}
                                        />
                                    </Stack>
                                </div> */}
                                {
                                    !isLoading &&
                                    <div className="row my-4">
                                        <div className="col-lg-6">
                                            <div className="pagination">
                                                <select onChange={handleCountSelect} value={pageSize}>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50" >50</option>
                                                    <option value="100" >100</option>
                                                </select>
                                                {Math.min(startItem, count)} to {endItem} of {count} Entries
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <Stack spacing={2}>
                                                <Pagination
                                                    count={totalPage}
                                                    variant="outlined"
                                                    shape="rounded"
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                />
                                            </Stack>
                                        </div>
                                    </div>
                                }
                            </>
                        ) : (
                            <>
                                {isLoading ? <TableLoader /> :
                                    <div className="make-center emptyList">
                                        <i className="fa-solid fa-box-archive"></i>
                                        <p>No Domains found</p>
                                    </div>
                                }
                            </>
                        )
                    }

                </div>
            </div>


        </>
    );
}

export default DomainList;
// {open ? (
//   <div className="container">
//     <CustomTemplate templateId={templateId} />
//     {/* userData={userData}
//     open={open}
//     setOpen={setOpen}
//     handleClose={handleClose} */}
//   </div>
// ) : null}