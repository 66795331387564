import { useState } from "react"
import CustomTemplate from "./CustomTemplate"
import { useLocation } from "react-router-dom"

function CreateTemplate(){
    const [templateId,setTemplateId] = useState(0)
    return(<>
    <div className="mainWrapper">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="commonForm">
                        
                        <CustomTemplate templateId={templateId} editable={true}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>)
}

export default CreateTemplate

export function UpdateTemplate(){
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

    const templateId = queryParams.get("templateId");
    return(<>
    <div className="mainWrapper">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="commonForm">
                        <CustomTemplate templateId={templateId} editable={true}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>)
}