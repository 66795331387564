import { Skeleton } from "@mui/material";

export default function TableLoader() {
  return (
    <table className="table table-striped  colorBlackata w-full">
      <tbody>
        <tr>
          <td>
            {" "}
            <Skeleton animation="wave" variant="text" height={50} />
          </td>
        </tr>
        <tr>
          <td>
            {" "}
            <Skeleton animation="wave" variant="text" height={50} />
          </td>
        </tr>
        <tr>
          <td>
            {" "}
            <Skeleton animation="wave" variant="text" height={50} />
          </td>
        </tr>
        <tr>
          <td>
            {" "}
            <Skeleton animation="wave" variant="text" height={50} />
          </td>
        </tr>{" "}
        <tr>
          <td>
            {" "}
            <Skeleton animation="wave" variant="text" height={50} />
          </td>
        </tr>{" "}
        <tr>
          <td>
            {" "}
            <Skeleton animation="wave" variant="text" height={50} />
          </td>
        </tr>{" "}
        <tr>
          <td>
            {" "}
            <Skeleton animation="wave" variant="text" height={50} />
          </td>
        </tr>{" "}
        <tr>
          <td>
            {" "}
            <Skeleton animation="wave" variant="text" height={50} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
