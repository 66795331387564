const storagePrefix = 'art_';

const storage = {
  setToken: (token) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  }, 
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },

  setEmail: (email) => {
    window.localStorage.setItem(`${storagePrefix}email`, JSON.stringify(email));
  },
  getEmail: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}email`));
  },
  clearEmail: () => {
    window.localStorage.removeItem(`${storagePrefix}email`);
  },
};

export default storage;
