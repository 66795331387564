// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editimgDiv img {
  width: 78%;
}

.bti i {
  color: #000;
  font-size: 18px;
}
.TPOsbc {
  /* border: 1px solid red; */
  margin-bottom: 25px;
}
.selectDiv {
  margin-top: 14px !important;
}
.backButton {
  width: 100%;
  position: relative;
}
.backButton i {
  font-size: 22px;
  cursor: pointer;
  color: #1976d2;
}

`, "",{"version":3,"sources":["webpack://./src/pages/protected/protected.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,eAAe;EACf,cAAc;AAChB","sourcesContent":[".editimgDiv img {\n  width: 78%;\n}\n\n.bti i {\n  color: #000;\n  font-size: 18px;\n}\n.TPOsbc {\n  /* border: 1px solid red; */\n  margin-bottom: 25px;\n}\n.selectDiv {\n  margin-top: 14px !important;\n}\n.backButton {\n  width: 100%;\n  position: relative;\n}\n.backButton i {\n  font-size: 22px;\n  cursor: pointer;\n  color: #1976d2;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
