/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import storage from "../../utils/storage";
import { Button, CircularProgress, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { API_URL } from "../../config";
import OpenModal from "../../components/Modal";
import "./protected.css";
import TableLoader from "../../components/Loader/TableLoader";
import CustomTemplate from "../../components/CustomTemplate";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import moment from 'moment';
import SendAsModal from '../../components/SendAsModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function UserList() {
  const token = storage.getToken();
  const [isLoading, setLoading] = useState(true);
  const [userListing, setUserListing] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(10)
  const [count, setCount] = useState(0)
  // const [userData, setuserData] = useState({
  //   primaryEmail: "",
  //   aliases: [],
  // });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemNum, setItemNum] = useState(1);
  const [open, setOpen] = useState(false);
  const [templateList, setTemplateList] = useState([])
  // const [userEmail,setUserEmail] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const [userDetails, setUserDetails] = useState("")
  const [lastUpdateTime, setLastUpdateTime] = useState("")
  const [syncUserButton, setSyncUserButton] = useState(false)
  const [startItem, setStartItem] = useState(0)
  const [endItem, setEndItem] = useState(0)
  const [sendAsOpen, setSendAsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    getUserList(searchTerm);
    setStartItem((currentPage - 1) * pageSize + 1);
  }, [currentPage, pageSize]);
  useEffect(() => {
    getTemplateList()
    checkLastUpdate()

  }, [])
  const checkLastUpdate = async () => {
    let apiUrl = `${API_URL}/sync-user-logs/`;

    try {
      const response = await axios.get(`${apiUrl}`);
      console.log("response", response?.data)
      setLastUpdateTime(response?.data?.data?.completed_at)
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        console.log(errorMessage);
        toast.error(errorMessage)
      } else {
        console.log("An error occurred");
      }
    }
  }
  lastUpdateTime && console.log("LAst update Time", new Date(lastUpdateTime))
  const handleCountSelect = (event) => {
    setCurrentPage(1)
    setPageSize(event.target.value)
  }
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // const handleSearch = (value) => {
  //   setSearchTerm(value)
  //   setCurrentPage(1)
  // }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    console.log("clicked");
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      console.log("searchTerm", value)
      setCurrentPage(1)
      getUserList(value)
    }, 500),
    []
  );
  const handleSearchChange = (value) => {
    console.log("value", value)
    setSearchTerm(value)
    debouncedSearch(value);
  }


  const getUserList = async (searchTerm) => {
    setLoading(true);
    let apiUrl = `${API_URL}/get-all-user?page=${currentPage}&page_size=${pageSize}&search=${searchTerm}`;

    try {
      const response = await axios.get(`${apiUrl}`);
      if (currentPage) {
        setItemNum((currentPage - 1) * pageSize + 1);
      }
      setCount(response?.data?.count)
      setUserListing(response?.data?.results);
      setTotalPage(response?.data?.total_pages);
      let end = Math.min(currentPage * pageSize, response?.data?.count)
      setEndItem(end)

      setLoading(false);

    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        console.log(errorMessage);
      } else {
        console.log("An error occurred");
      }
      setLoading(false);
    }
  };

  const handleEdit = (user) => {
    // setuserData(item);
    setUserDetails(user)
    // setUserEmail(user.email)

    handleClickOpen();
  };
  const handleViewSendAs = async (user) => {
    setSelectedUser(user);
    setSendAsOpen(true);
  };
  const getTemplateList = async () => {
    setLoading(true);
    let apiUrl = `${API_URL}/templates/?page=${currentPage}&page_size=100`;
    try {
      const response = await axios.get(`${apiUrl}`);
      if (currentPage) {
        setItemNum(currentPage * 10 - 9);
      }
      setTemplateList(response?.data?.results);
      setTotalPage(response?.data?.total_pages);
      // setLoading(false);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        console.log(errorMessage);
      } else {
        console.log("An error occurred");
      }
      // setLoading(false);
    }
  };
  const syncUser = async () => {
    setSyncUserButton(true)
    let apiUrl = `${API_URL}/sync-user/`;
    try {
      const response = await axios.get(`${apiUrl}`);
      console.log("response", response.data)
      if (response?.data?.status) {
        toast.success(response.data.message)
      }
      // setLoading(false);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        console.log(errorMessage);
      } else {
        console.log("An error occurred");
      }
      // setLoading(false);
    }
  }
  return (
    <>
      <div className="mainWrapper topbar">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div className="main-header mb-0">
                <h3 className="mt-3">User List</h3>
              </div>
            </div>
            <div className="col-md-5">
              <div className="buttonSection">
                <p>Last Updated on: <span>{lastUpdateTime ? (moment(lastUpdateTime).format('MMMM Do YYYY, h:mm:ss A')) : "In Progress..."}</span></p>
                <button className="btn btn-sync" title="Sync User" onClick={syncUser} disabled={syncUserButton} ><i class="fa-solid fa-arrows-rotate"></i></button>

                <div className="searchBar">
                  <TextField
                    id="search"
                    type="search"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => handleSearchChange(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mainWrapper userListing commonListing">
        <div className="container-fluid mt-3">

          {/* <div className="row">
          <div className="col-md-9">
            <div className="main-header mb-3">
              <h3 className="mt-2">User List</h3>
            </div>
          </div>
          <div className="col-md-3">
            <div className="searchBar mb-3">
              <TextField
                id="search"
                type="search"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => handleSearchChange(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div> */}

          {userListing && userListing.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>S.no.</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>JobTitle</StyledTableCell>
                      <StyledTableCell>Department</StyledTableCell>
                      <StyledTableCell><span>Company Name</span></StyledTableCell>
                      {/* <StyledTableCell>Company Address</StyledTableCell> */}
                      <StyledTableCell>Direct Phone Number</StyledTableCell>
                      <StyledTableCell>Schedule Meeting Link</StyledTableCell>
                      <StyledTableCell>Virtual Waiting Room Link</StyledTableCell>
                      <StyledTableCell>Supervisor EmailID</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading ? (
                    <td colSpan={12}>
                      <TableLoader />
                    </td>
                  ) : (
                    <TableBody>
                      {userListing?.map((item, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="item">
                            {index + itemNum}
                          </StyledTableCell>
                          <StyledTableCell className="userName" component="th" scope="item">
                            <span className="pointer" onClick={(e) => { handleEdit(item); }}>
                              {item.name.fullName}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>{item.primaryEmail || "-"}</StyledTableCell>
                          <StyledTableCell>{item.JobTitle || "-"}</StyledTableCell>
                          <StyledTableCell>{item.Department || "-"}</StyledTableCell>
                          <StyledTableCell>{item.CompanyName || "-"}</StyledTableCell>
                          {/* <StyledTableCell>{item.CompanyAddress || "-"}</StyledTableCell> */}
                          <StyledTableCell>{item.DirectPhoneNumber || "-"}</StyledTableCell>
                          <StyledTableCell> {item.ScheduleMeetingLink ? (
                            <a href={item.ScheduleMeetingLink} target="_blank" rel="noopener noreferrer">
                              {item.ScheduleMeetingLink}
                            </a>
                          ) : (
                            "-"
                          )}</StyledTableCell>
                          <StyledTableCell>{item.VirtualWaitingRoomLink ? (
                            <a href={item.VirtualWaitingRoomLink} target="_blank" rel="noopener noreferrer">
                              {item.VirtualWaitingRoomLink}
                            </a>
                          ) : (
                            "-"
                          )}</StyledTableCell>
                          <StyledTableCell>{item.SupervisorEmailID || "-"}</StyledTableCell>
                          <StyledTableCell>
                            <div className="action-buttons">
                              <Button
                                className="commonAction editButton"
                                title="View Details"
                                onClick={(e) => {
                                  handleEdit(item);
                                }}
                              >
                                <i className="fa-solid fa-eye"></i>
                              </Button>
                              <Button
                                className="commonAction sendAsButton"
                                title="View SendAs Emails"
                                onClick={(e) => {
                                  handleViewSendAs(item);
                                }}
                              >
                                <i className="fa-solid fa-envelope"></i>
                              </Button>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {
                !isLoading &&
                <div className="row my-4">
                  <div className="col-lg-6">
                    <div className="pagination">
                      <select onChange={handleCountSelect} value={pageSize}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50" >50</option>
                        <option value="100" >100</option>
                      </select>
                      {Math.min(startItem, count)} to {endItem} of {count} Entries
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Stack spacing={2}>
                      <Pagination
                        count={totalPage}
                        variant="outlined"
                        shape="rounded"
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    </Stack>
                  </div>
                </div>
              }

            </>
          ) : (
            <>
              <div className="make-center emptyList">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <i className="fa-solid fa-box-archive"></i>
                    <p>No Entry found</p>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {open && (
        <OpenModal
          userDetails={userDetails}
          templateData={templateList}
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
        />
      )}

      {sendAsOpen && selectedUser && (
        <SendAsModal
          open={sendAsOpen}
          onClose={() => {
            setSendAsOpen(false);
            setSelectedUser(null);
          }}
          user={selectedUser}
        />
      )}
    </>
  );
}

export default UserList;

