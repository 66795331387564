// import { useState } from "react"
// import CustomTemplate from "./CustomTemplate"
// import { useLocation } from "react-router-dom"

// function CreateTest() {
//     const [templateId, setTemplateId] = useState(0)
//     console.log("templateId", templateId)
//     return (<>
//         <div className="row">





//             <div className="row">
//                 <div className="col-md-6">
//                     <div className="row">
//                         <div className="col-md-6 border p-3">
//                             <h4>User List</h4>
//                             <div className="users">
//                                 <div className="checkBoxSection">
//                                     {/* Primary email checkbox */}
//                                     <span>
//                                         <input
//                                             type="checkbox"
//                                             checked={selectedEmail === userData?.primaryEmail}
//                                             onChange={() => handleEmailCheckboxClick(userData?.primaryEmail)}
//                                         />
//                                     </span>
//                                     <span className="aliaseName">{userData.primaryEmail}</span>
//                                 </div>

//                                 {/* Aliases checkboxes */}
//                                 {userData.aliases.map((alias, index) => (
//                                     <div className="checkBoxSection" key={index}>
//                                         <span>
//                                             <input
//                                                 type="checkbox"
//                                                 checked={selectedEmail === alias}
//                                                 onChange={() => handleEmailCheckboxClick(alias)}
//                                             />
//                                         </span>
//                                         <span className="aliaseName">{alias}</span>
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>

//                         {templateData.length > 0 ? (
//                             <div className="col-md-6 border p-3">

//                                 <h4>Template List</h4>
//                                 {templateData.map((item, index) => (
//                                     <div className="checkBoxSection" key={index}>
//                                         <span>
//                                             <input
//                                                 type="checkbox"
//                                                 checked={selectedTemplate === item.id}
//                                                 onChange={() => handleCheckboxClick(item.id)}
//                                             />
//                                         </span>
//                                         <span className="aliaseName">{item.template_name}</span>
//                                     </div>
//                                 ))}
//                             </div>
//                         ) : null}

//                     </div>
//                 </div>

//                 <div className="col-md-6">
//                     <CustomTemplate templateId={templateId} editable={true} />
//                 </div>
//             </div>






//         </div>
//     </>)
// }

// export default CreateTest

