import { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { API_URL, EDITOR_KEY } from "../config";
import StaticVariables, { linkMappings } from "../utils/constant";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";

const CustomTemplate = ({ templateId, email, editable, userDetails, handleClose, setHtmlContent, multiple, domain }) => {
    const [editorContent, setEditorContent] = useState("");  // Initialize content as empty
    // const [templateId,setTemplateId] = useState(id||0)
    const [templateName, setTemplateName] = useState("");
    const [emptyData, setEmptyData] = useState(false);
    const [emptyName, setEmptyName] = useState(false);
    const [defaultReply, setDefaultReply] = useState(false)
    const [defaultNewEmail, setDefaultNewEmail] = useState(false)
    const [signatureName, setSignatureName] = useState('')
    const navigate = useNavigate()
    // Function to fetch template data
    const getTemplateData = async () => {
        let apiUrl = `${API_URL}/templates/${templateId}/`;
        try {
            const response = await axios.get(apiUrl);
            // Set the editor content and template name from API response
            setEditorContent(response?.data?.data?.content || "");
            setTemplateName(response?.data?.data?.template_name || "");
        } catch (error) {
            if (error.response) {
                const errorMessage = error.response.data.message;
                console.log(errorMessage);
            } else {
                console.log("An error occurred");
            }
        }
    };
    // Fetch template data when templateId changes
    useEffect(() => {
        if (templateId) {
            getTemplateData();
        } else {
            setEditorContent("")
            setTemplateName("")
        }
    }, [templateId]);

    // Handle content changes in the editor
    const handleEditorChange = (content, editor) => {
        // Highlight code using Prism.js after the content has changed
        setEditorContent(content);
    };

    // Handle template name input change
    const handleNameChange = (e) => {
        setTemplateName(e.target.value);
        if (e.target.value) setEmptyName(false);  // Clear validation error
    };

    // Handle form submission
    const handleSubmit = () => {
        let hasError = false;

        if (!editorContent) {
            setEmptyData(true);
            hasError = true;
        } else {
            setEmptyData(false);
        }

        if (!templateName) {
            setEmptyName(true);
            hasError = true;
        } else {
            setEmptyName(false);
        }

        if (hasError) return;

        // Submit the data to the API
        fetch(`${API_URL}/templates/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                template_name: templateName,
                content: editorContent,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                // if(data.data.status)
                // handleClose()
                toast.success("Template created successfully!");

                editable && navigate("/template")

            })
            .catch((error) => {
                console.error("Error submitting content:", error);
                toast.error("An error occurred while submitting the content.");
            });
    };
    const handlePreviewSignature = () => {
        fetch(`${API_URL}/get-preview/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                // name:signatureName,
                email: email,
                content: editorContent,
                new_email: defaultNewEmail,
                // forward_email:defaultReply,
                user_details: [userDetails]
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                setHtmlContent(data.data)

            })
            .catch((error) => {
                console.error("Error submitting content:", error);
                // toast.error("An error occurred while submitting the content.");
            });

    }
    const handleUpdateSignature = () => {
        let requestBody = JSON.stringify({
            // name:signatureName,
            email: email,
            content: editorContent,
            new_email: defaultNewEmail,
            // forward_email:defaultReply,
            user_details: [userDetails]
        })
        if (multiple) {
            requestBody = JSON.stringify({
                // name:signatureName,
                content: editorContent,
                new_email: defaultNewEmail,
                // forward_email:defaultReply,
                user_details: userDetails,
                multiple: true,
                domain: domain
            })
        }
        fetch(`${API_URL}/signature-update/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: requestBody
        })
            .then((response) => response.json())
            .then((data) => {
                toast.success("Signature Updated Successfully!", {
                    // position: toast.POSITION.BOTTOM_CENTER,  // Position of the toast
                    // autoClose: 3000,  // Duration for the toast to be visible
                });
                handleClose()
                navigate("/user-list")

            })
            .catch((error) => {
                console.error("Error submitting content:", error);
                // toast.error("An error occurred while submitting the content.");
            });

    }
    const handleUpdateTemplate = () => {
        let hasError = false;

        if (!editorContent) {
            setEmptyData(true);
            hasError = true;
        } else {
            setEmptyData(false);
        }

        if (!templateName) {
            setEmptyName(true);
            hasError = true;
        } else {
            setEmptyName(false);
        }

        if (hasError) return;

        // Submit the data to the API
        fetch(`${API_URL}/templates/${templateId}/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                template_name: templateName,
                content: editorContent,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Successfully submitted:", data);
                toast.success("Template updated successfully!")
                // ,{
                //     position: toast.POSITION.BOTTOM_CENTER,  // Position of the toast
                //     autoClose: 3000,  // Duration for the toast to be visible
                //   });
                editable && navigate("/template")

            })
            .catch((error) => {
                console.error("Error submitting content:", error);
                toast.error("An error occurred while submitting the content.");
            });
    };
    const handleInputChange = (e) => {
        setSignatureName(e.target.value)
    }
    const handleSetNewEmail = (e) => {
        setDefaultNewEmail(e.target.value)
    }

    const handleSetReplyEmail = (e) => {
        setDefaultReply(e.target.value)
    }
    return (
        <>
            {/* <p>My TinyMCE Editor</p> */}
            {
                editable ? (
                    <>
                        <label>Template Name</label>
                        <input
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder="Enter template name"
                            value={templateName}
                            onChange={handleNameChange}
                        /></>
                    // emptyName && <p className="error-message">Template name is required</p> 
                ) : ""
            }


            <Editor
                apiKey={EDITOR_KEY}
                value={editorContent} // Set the editor content here
                init={{
                    height: 500,
                    menubar: false,
                    content_style: `
                    body {
                      font-family: Helvetica, Arial, sans-serif;
                      font-size: 16px;
                      margin: 0; /* Remove default margin */
                      padding: 0; /* Remove default padding */
                      line-height: 1.6; /* Adjust line-height if needed */
                    }
                    p {
                      margin: 0 0 5px 0; /* Reduce margin for paragraphs */
                      padding: 0;
                    }
                    h1, h2, h3, h4, h5, h6 {
                      margin: 0 0 10px 0; /* Reduce margins for headings */
                      padding: 0;
                    }
                    ul, ol {
                      margin: 0;
                      padding: 0;
                      list-style-position: inside;
                    }
                    pre {
                      margin: 10px 0; /* Reduced margin around code blocks */
                      padding: 15px;
                      background-color: #f5f5f5;
                      border-radius: 5px;
                      font-family: "Courier New", Courier, monospace;
                      font-size: 14px;
                      white-space: pre-wrap;
                      word-wrap: break-word;
                    }
                  `,
                    menubar: false,
                    plugins: [
                        'link', 'table', 'lists', 'advlist', 'image', 'code', 'fontselect', 'fontfamily', 'fontsize' // Ensure fontsizeselect is in the plugins list
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | fontselect | fontfamily | fontsize |alignleft aligncenter alignright | bullist numlist | link | image | code | customVariables ', // Add fontsizeselect to toolbar
                    link_title: true,           // Show a title field in the link dialog
                    link_assume_external_targets: true, // Automatically assumes external links
                    link_default_protocol: 'https',
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: "image",
                    file_picker_callback: (cb, value, meta) => {
                        const input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");

                        input.addEventListener("change", (e) => {
                            const file = e.target.files[0];
                            if (file) {
                                const reader = new FileReader();
                                reader.onload = () => {
                                    const base64 = reader.result;
                                    cb(base64, { title: file.name });
                                };
                                reader.readAsDataURL(file);
                            }
                        });

                        input.click();
                    },
                    // content_style:
                    //     "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
                    setup: (editor) => {

                        editor.ui.registry.addMenuButton("customVariables", {
                            text: "Insert Variables",
                            fetch: (callback) => {
                                const items = StaticVariables.map((variable) => ({
                                    type: "menuitem",
                                    text: `${variable}`,
                                    onAction: () => {
                                        // Insert specific content based on the variable selected
                                        if (linkMappings.includes(variable)) {
                                            // Insert the custom <li> element for "Scheduled meeting link"
                                            const content = `
                                            <li style="font-size:9px;margin-right:10px;list-style-type:none;color:#fff;">
                                              <a style="text-decoration:none;color:#000;font-size:9px;" href="{{${variable.toLowerCase().trim().replaceAll(" ", "_")}}}">➜ ${variable}</a>
                                            </li>
                                          `;
                                            editor.insertContent(content); // Insert content into the editor
                                        } else {
                                            const formattedVariable = variable.toLowerCase().trim().replaceAll(" ", "_");
                                            editor.insertContent(`<span id="${formattedVariable}">{{${formattedVariable}}}</span>`);
                                            // Handle other variables as simple placeholders (or extend as needed)
                                            //   editor.insertContent(`{{${variable.toLowerCase().trim().replaceAll(" ", "_")}}}`);
                                        }
                                    },
                                }));

                                callback(items);
                            },
                        });
                    },
                }}
                onEditorChange={handleEditorChange}
            />
            {emptyData && <p className="error-message">Editor content is required</p>}
            <div>

                {editable ? templateId && templateId != "undefined" ?
                    (<Tooltip title={"Update template"}>
                        <IconButton>
                            <button className="btn btn-submit" onClick={handleUpdateTemplate}>Update</button>
                        </IconButton>
                    </Tooltip>)
                    :
                    (
                        <Tooltip title={"Create new template"}><IconButton>
                            <button className="btn btn-submit" onClick={handleSubmit}>Submit</button>
                        </IconButton></Tooltip>
                    )
                    :

                    <>
                        <div className="mt-2">
                            <>
                                {/* {!templateId ? <> */}
                                <label>Template Name</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    placeholder="Enter template name"
                                    value={templateName}
                                    onChange={handleNameChange}
                                />
                            </>
                            {/* :""}  */}

                            {/* </> */}

                            <div className="d-flex align-itens-center mt-3 gap-1">
                                {templateId ? (<>
                                    <Tooltip className="p-0" title={"Update existing template"}>
                                        <IconButton>
                                            <button className="btn-submit mt-0" style={{ padding: '6px 20px' }} onClick={handleUpdateTemplate}>Save</button>

                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip className="p-0" title={"Create new template"}>
                                        <IconButton>
                                            <button className="btn-submit mt-0" style={{ padding: '6px 20px' }} onClick={handleSubmit}>Save As New</button>

                                        </IconButton>
                                    </Tooltip>
                                </>) :
                                    <Tooltip className="p-0" title={"Create new template"}>
                                        <IconButton>
                                            <button className="btn-submit mt-0" style={{ padding: '6px 20px' }} onClick={handleSubmit}>Save</button>

                                        </IconButton>
                                    </Tooltip>
                                }
                                {

                                    !multiple && <>
                                        <Tooltip className="previewOuter" title={"Preview your signature"}> <IconButton>
                                            <button style={{ backgroundColor: '#c9c9c9', fontWeight: '500' }} className="btn btn-preview mt-0" onClick={handlePreviewSignature}>Preview</button>
                                        </IconButton> </Tooltip>
                                    </>
                                }
                            </div>
                            {/* {
                                templateId ?( <p className="mt-2 fw-6"><i><span style={{color: '#f00'}}>*Note:</span> This will update current template.</i></p> ):( <p className="mt-2 fw-6"><i><span style={{color: '#f00'}}>*Note:</span> This will create new template.</i></p>)

                            } */}
                        </div>

                        <div className="default-signature mb-3">
                            {/* <div className="sigNameSection">
                                <p>Signature Name :</p> <input type="text" id="textInput" value={signatureName} onChange={handleInputChange} className="form-control" />
                           </div> */}
                            {/* <h4>Signature defaults</h4> */}
                            <div className="row">
                                <div className="col-lg-4">
                                    <p className="fw-semibold">For New Email Use</p>
                                    <div className="d-flex gap-5">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="true" checked={defaultNewEmail === 'true'} onChange={handleSetNewEmail} />
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                True
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="false" checked={defaultNewEmail === 'false'} onChange={handleSetNewEmail} />
                                            <label class="form-check-label" for="flexRadioDefault2">
                                                False
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4">
                                    <p>Forward Use</p>
                                    <div className="d-flex gap-5">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefaultNew" id="flexRadioDefault3"  value="true"  checked={ defaultReply=== 'true'} onChange={handleSetReplyEmail} 
                                             />
                                            <label class="form-check-label" for="flexRadioDefault3">
                                                True
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefaultNew" id="flexRadioDefault4"  value="false"  checked={defaultReply === 'false'} onChange={handleSetReplyEmail}  />
                                            <label class="form-check-label" for="flexRadioDefault4">
                                                False
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <Tooltip className="p=0" title={"Update signature"}>
                            <IconButton>

                                <button className="btn-submit" onClick={handleUpdateSignature}>Submit</button>
                            </IconButton>
                        </Tooltip>

                    </>}
            </div >
        </>
    );
};

export default CustomTemplate;



