const StaticVariables = [
  "Name",
  "Job Title",
  "Department",
  "Company Name",
  "Company Address",
  "Direct Phone Number",
  "Main Phone Number",
  "Schedule Meeting Link",
  "Virtual Waiting Room Link",
  "Supervisor Name",
  "Supervisor Job Title",
  "Supervisor Email ID",
  "Supervisor Ring Central Phone Number",
];
  export default StaticVariables

  export const linkMappings = [
    "Schedule Meeting Link",
    "Virtual Waiting Room Link"
   
  ];